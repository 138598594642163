/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 14, 2019 */
@font-face {
  font-family: 'db_heavent';
  src: url("/static/media/DB Heavent Thin v3.2.757e45b3.ttf") format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'db_heavent';
  src: url("/static/media/DB Heavent Li v3.2.93cbf0ab.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'db_heavent';
  src: url("/static/media/DB Heavent Med v3.2.ef0ab8eb.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'db_heavent';
  src: url("/static/media/DB Heavent Bd v3.2.58c493b1.ttf") format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'BrowalliaUPC';
  src: url(/static/media/BrowalliaUPC.01f264f2.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sarabun';
  src: url(/static/media/Sarabun-Regular.9cef964b.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

html,
body {
  font-size: 18px;
  font-family: 'db_heavent';
}
body {
  margin: 0;
  padding: 0;
}
body * {
}

h1 {
  font-size: 1.602rem;
}
h2 {
  font-size: 1.424rem;
}
h3 {
  font-size: 1.266rem;
}
h4 {
  font-size: 1.125rem;
}
p {
  font-size: 1rem;
  margin-bottom: 0;
}
h5 {
  font-size: 0.889rem;
}
h6 {
  font-size: 0.79rem;
}

h1.display {
  font-size: 2.027rem;
}
h2.display {
  font-size: 1.802rem;
}
h3.display {
  font-size: 1.602rem;
}
h4.display {
  font-size: 1.424rem;
}
p.display {
  font-size: 1.266rem;
  margin-bottom: 0;
}
h5.display {
  font-size: 1.125rem;
}
h6.display {
  font-size: 1rem;
}


h1,h2,h3,h4,h5,h5,h6,p{
  
  margin:0;
}

[data-class='wp-white'],
[data-class='elect-white'],
[data-class='cleverse-white'] {
  visibility: hidden;
}
#root {
  background-color: #f2f1f0;
  transition: background 0.5s;
}
body[darkmode='true'] #root {
  background: black;
}
body[darkmode='true'] [data-class='wp-white'],
body[darkmode='true'] [data-class='elect-white'],
body[darkmode='true'] [data-class='cleverse-white'] {
  visibility: visible;
}
body[darkmode='true'] [data-class='wp-black'],
body[darkmode='true'] [data-class='elect-black'],
body[darkmode='true'] [data-class='cleverse-black'] {
  visibility: hidden;
}

body[darkmode='true'] .anticon-menu svg {
  fill: white;
}

body[darkmode='true'] [data-controller-bg='true'] {
  background: transparent;
}
body[darkmode='true'] [data-controller-bg='true'] a {
  color: white;
}
body[darkmode='true'] [data-controller-bg='true'] li {
  border-color: white;
}
body[darkmode='true'] [data-controller-bg='true'] a span svg {
  fill: white;
}


@media only screen and (max-width: 1920px)  {
  
  body,html{
    font-size:20px;
    font-size:1vw;
  }
}

@media only screen and (max-width: 1600px)  {
  body,html{
    font-size:18.5px;
    font-size:1.2vw;
  }
}
@media only screen and (max-width: 1366px)  {
  body,html{
    font-size:14px;
    font-size:1.3vw;
  }
}

@media only screen and (max-width: 1280px)  {
  body,html{
    font-size:13px;
    font-size:1.3vw;
  }
}

@media only screen and (max-width: 1024px)  {
  body,html{
    font-size:18px;
  }
}

@media only screen and (max-width: 900px)  {
  body,html{
    font-size:16px;
  }
}


@media only screen and (max-width: 450px)  {
  body,html{
    font-size:16px;
    font-size:4vw;
  }
}

/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
 */

.slick-vertical .slick-arrow {
  left: 50%;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  width: 40px;
  height: 40px;
}
.slick-vertical .slick-prev {
  top: -40px;
}

.slick-vertical .slick-next {
  top: inherit;
  bottom: -40px;
}

.slick-vertical .slick-next::before,
.slick-vertical .slick-prev::before {
  font-size: 40px;
  color: #c5c5c5;
}

.bottom-tooltip-container {
  background: linear-gradient(120deg, #2590d2 0%, #4c86ff 100%);
  z-index: 10;
  padding: 0.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  border-radius: 6px;
}

.bottom-tooltip {
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-inline-flex;
  display: inline-flex;
  vertical-align: middle;
}

.bottom-tooltip img {
  max-width: 2rem;
  height: auto;
  padding: 0.5rem;
  object-fit: contain;
  object-position: center center;
}

.bottom-tooltip h3 {
  margin-top: 0;
  -webkit-align-self: center;
          align-self: center;
  color: white;
  margin-bottom: 0rem;
  letter-spacing: 0.025rem;
  font-weight: 400;
}

.top-tooltip {
  background-color: white;
  z-index: 10;
  width: 120px;
  padding: 0.5rem 2rem;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  border-radius: 6px;
}

.top-tooltip h2 {
  margin-top: 0;
  -webkit-align-self: center;
          align-self: center;
  margin-bottom: 0rem;
  letter-spacing: 0.025rem;
  font-weight: 400;
}

.__react_component_tooltip.show {
  opacity: 1;
}

.SectionContainer {
  z-index: 200;
}

