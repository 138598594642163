html,
body {
  font-size: 18px;
  font-family: 'db_heavent';
}
body {
  margin: 0;
  padding: 0;
}
body * {
}

h1 {
  font-size: 1.602rem;
}
h2 {
  font-size: 1.424rem;
}
h3 {
  font-size: 1.266rem;
}
h4 {
  font-size: 1.125rem;
}
p {
  font-size: 1rem;
  margin-bottom: 0;
}
h5 {
  font-size: 0.889rem;
}
h6 {
  font-size: 0.79rem;
}

h1.display {
  font-size: 2.027rem;
}
h2.display {
  font-size: 1.802rem;
}
h3.display {
  font-size: 1.602rem;
}
h4.display {
  font-size: 1.424rem;
}
p.display {
  font-size: 1.266rem;
  margin-bottom: 0;
}
h5.display {
  font-size: 1.125rem;
}
h6.display {
  font-size: 1rem;
}


h1,h2,h3,h4,h5,h5,h6,p{
  
  margin:0;
}

[data-class='wp-white'],
[data-class='elect-white'],
[data-class='cleverse-white'] {
  visibility: hidden;
}
#root {
  background-color: #f2f1f0;
  transition: background 0.5s;
}
body[darkmode='true'] #root {
  background: black;
}
body[darkmode='true'] [data-class='wp-white'],
body[darkmode='true'] [data-class='elect-white'],
body[darkmode='true'] [data-class='cleverse-white'] {
  visibility: visible;
}
body[darkmode='true'] [data-class='wp-black'],
body[darkmode='true'] [data-class='elect-black'],
body[darkmode='true'] [data-class='cleverse-black'] {
  visibility: hidden;
}

body[darkmode='true'] .anticon-menu svg {
  fill: white;
}

body[darkmode='true'] [data-controller-bg='true'] {
  background: transparent;
}
body[darkmode='true'] [data-controller-bg='true'] a {
  color: white;
}
body[darkmode='true'] [data-controller-bg='true'] li {
  border-color: white;
}
body[darkmode='true'] [data-controller-bg='true'] a span svg {
  fill: white;
}


@media only screen and (max-width: 1920px)  {
  
  body,html{
    font-size:20px;
    font-size:1vw;
  }
}

@media only screen and (max-width: 1600px)  {
  body,html{
    font-size:18.5px;
    font-size:1.2vw;
  }
}
@media only screen and (max-width: 1366px)  {
  body,html{
    font-size:14px;
    font-size:1.3vw;
  }
}

@media only screen and (max-width: 1280px)  {
  body,html{
    font-size:13px;
    font-size:1.3vw;
  }
}

@media only screen and (max-width: 1024px)  {
  body,html{
    font-size:18px;
  }
}

@media only screen and (max-width: 900px)  {
  body,html{
    font-size:16px;
  }
}


@media only screen and (max-width: 450px)  {
  body,html{
    font-size:16px;
    font-size:4vw;
  }
}
