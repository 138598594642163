/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 14, 2019 */
@font-face {
  font-family: 'db_heavent';
  src: url('./fonts/DB Heavent Thin v3.2.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'db_heavent';
  src: url('./fonts/DB Heavent Li v3.2.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'db_heavent';
  src: url('./fonts/DB Heavent Med v3.2.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'db_heavent';
  src: url('./fonts/DB Heavent Bd v3.2.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'BrowalliaUPC';
  src: url('./fonts/BrowalliaUPC.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Sarabun';
  src: url('./fonts/Sarabun-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
