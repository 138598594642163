/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
 */
@import './styles/fonts.less';
@import './styles/global.less';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.slick-vertical .slick-arrow {
  left: 50%;
  transform: rotate(90deg);
  width: 40px;
  height: 40px;
}
.slick-vertical .slick-prev {
  top: -40px;
}

.slick-vertical .slick-next {
  top: inherit;
  bottom: -40px;
}

.slick-vertical .slick-next::before,
.slick-vertical .slick-prev::before {
  font-size: 40px;
  color: #c5c5c5;
}

.bottom-tooltip-container {
  background: linear-gradient(120deg, #2590d2 0%, #4c86ff 100%);
  z-index: 10;
  padding: 0.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  border-radius: 6px;
}

.bottom-tooltip {
  text-align: center;
  align-items: center;
  display: inline-flex;
  vertical-align: middle;
}

.bottom-tooltip img {
  max-width: 2rem;
  height: auto;
  padding: 0.5rem;
  object-fit: contain;
  object-position: center center;
}

.bottom-tooltip h3 {
  margin-top: 0;
  align-self: center;
  color: white;
  margin-bottom: 0rem;
  letter-spacing: 0.025rem;
  font-weight: 400;
}

.top-tooltip {
  background-color: white;
  z-index: 10;
  width: 120px;
  padding: 0.5rem 2rem;
  text-align: center;
  align-items: center;
  position: absolute;
  display: flex;
  border-radius: 6px;
}

.top-tooltip h2 {
  margin-top: 0;
  align-self: center;
  margin-bottom: 0rem;
  letter-spacing: 0.025rem;
  font-weight: 400;
}

.__react_component_tooltip.show {
  opacity: 1;
}

.SectionContainer {
  z-index: 200;
}
